import Link from 'next/link'
import dynamic from 'next/dynamic'

import { PlatformSectionContainer } from './style'
import { VTRCONNECT_LINK } from 'shared/constants'
import useWindowDimensions from 'shared/hooks/useWindowDimensions'
const Carousel = dynamic(() => import('./components/Carousel'), {
  ssr: false,
})

const PlatformSection = () => {
  const { isMobile } = useWindowDimensions()
  return (
    <>
      {isMobile ? null : (
        <PlatformSectionContainer>
          <Carousel />

          <p>
            Please Visit{' '}
            <Link
              href={`${VTRCONNECT_LINK}/Welcome`}
              className='highlight'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.VTRConnect.com/Welcome
            </Link>{' '}
            to learn about the platform in full.{' '}
            <Link
              href={`${VTRCONNECT_LINK}`}
              className='highlight'
              target='_blank'
              rel='noopener noreferrer'
            >
              www.VTRConnect.com
            </Link>{' '}
            is owned and operated by VTR SERVICES LLC.
          </p>
        </PlatformSectionContainer>
      )}
    </>
  )
}

export default PlatformSection
